<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet
        :canBack="true"
        :colorIcon="'#052633'"
        :title="'Tambah Associate Baru'"
      />
      <v-row no-gutters class="pa-4">
        <v-col cols="12" md="8" class="pa-2">
          <div>
            <p class="medium_txt bold ma-0">Nama</p>
            <v-text-field
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama di sini"
              v-model="field.nama"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <p class="medium_txt bold ma-0">NIP</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  placeholder="Silakan isi nip di sini"
                  v-model="field.nip"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <p class="medium_txt bold ma-0">Username</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  placeholder="Silakan isi username di sini"
                  v-model="field.username"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <p class="medium_txt bold ma-0">No. Telepon</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  placeholder="Silakan isi no. telepon di sini"
                  v-model="field.phone"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <p class="medium_txt bold ma-0">Email</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  v-model="field.email"
                  placeholder="Silakan isi email di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <p class="medium_txt bold ma-0">Tanggal Lahir</p>
                <v-menu
                  v-model="showPickerLahir"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_lahir"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tanggal lahir di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_lahir"
                    @input="closeDateMenu(field.tgl_lahir, 'lahir')"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <p class="medium_txt bold ma-0">Kata Sandi</p>
                <v-text-field
                  class="rounded-lg"
                  v-model="field.password"
                  placeholder="Silakan isi kata sandi di sini"
                  color="#0D47A1"
                  :error="errors.password"
                  dense
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3">
            <p class="medium_txt bold ma-0">Jenis Kelamin</p>
            <v-select
              placeholder="Silakan pilih jenis kelamin di sini"
              outlined
              hide-details
              :items="optGender"
              item-text="text"
              item-value="val"
              v-model="field.jenis_kelamin"
              dense
            ></v-select>
          </div>
          <div class="mt-3">
            <p class="medium_txt bold ma-0">Alamat</p>
            <v-textarea
              class="rounded-lg"
              outlined
              placeholder="Silakan isi alamat di sini"
              v-model="field.alamat"
              rows="3"
              color="#0D47A1"
              dense
              hide-details
            ></v-textarea>
          </div>
          <div class="mt-3">
            <v-row no-gutters>
              <v-col cols="6" class="pr-2">
                <p class="medium_txt bold ma-0">Departemen</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  v-model="field.department"
                  placeholder="Silakan isi departemen di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-2">
                <p class="medium_txt bold ma-0">Posisi</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  v-model="field.jabatan"
                  placeholder="Silakan isi jabatan di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex align-center mt-4">
            <v-btn
              :disabled="loading ? true : false"
              depressed
              class="mr-4 text-capitalize"
              outlined
              color="#BD9028"
              @click="$router.go(-1)"
            >
              <div style="color: black">Cancel</div>
            </v-btn>
            <v-btn
              depressed
              class="text-capitalize bg_default"
              @click="createData"
              dark
            >
              <h4 v-if="!loading">Simpan</h4>
              <h4 v-if="loading">Loading</h4>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <v-card flat outlined class="pa-3">
            <h3>Info Tambahan</h3>
            <div class="mt-3">
              <div>
                <p class="medium_txt bold ma-0">No. SIP</p>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  placeholder="Silakan isi no. sip di sini"
                  v-model="field.no_sipp"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <div class="mt-3">
                <p class="medium_txt bold ma-0">Tanggal Masuk</p>
                <v-menu
                  v-model="showPickerMasuk"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_masuk"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tanggal masuk di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                      style="width: 60%"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_masuk"
                    @input="closeDateMenu(field.tgl_masuk, 'masuk')"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-3">
                <p class="medium_txt bold ma-0">Status Associate</p>
                <v-radio-group
                  v-model="field.status_associate"
                  hide-details
                  class="ma-0"
                  dense
                  row
                >
                  <v-radio label="Aktif" color="#006341" value="1"></v-radio>
                  <v-radio
                    label="Tidak Aktif"
                    color="#006341"
                    value="0"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="mt-3">
                <p class="medium_txt bold ma-0">Tanggal Keluar</p>
                <v-menu
                  v-model="showPickerKeluar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="field.tgl_keluar"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tanggal keluar di sini"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                      style="width: 60%"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.tgl_keluar"
                    @input="closeDateMenu(field.tgl_keluar, 'keluar')"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-3">
                <p class="medium_txt bold ma-0">Pendidikan Terakhir</p>
                <v-select
                  placeholder="Silakan isi pendidikan terakhir di sini"
                  outlined
                  hide-details
                  :items="optPendidikanTerakhir"
                  item-text="text"
                  item-value="val"
                  v-model="field.pendidikan_terakhir"
                  dense
                ></v-select>
              </div>
              <div class="mt-3">
                <p class="medium_txt bold ma-0">Gaji</p>
                <v-select
                  placeholder="Silakan pilih gaji di sini"
                  outlined
                  hide-details
                  :items="optGaji"
                  item-text="text"
                  item-value="val"
                  v-model="field.gaji"
                  dense
                ></v-select>
              </div>
              <div class="mt-3">
                <p class="medium_txt bold ma-0">Status Associate</p>
                <v-radio-group
                  v-model="field.tipe_associate"
                  hide-details
                  class="ma-0"
                  dense
                  row
                >
                  <v-radio
                    label="Kontrak"
                    color="#006341"
                    value="Kontrak"
                  ></v-radio>
                  <v-radio
                    label="Tetap"
                    color="#006341"
                    value="Permanen"
                  ></v-radio>
                </v-radio-group>
                <v-text-field
                  class="rounded-lg mt-1"
                  outlined
                  placeholder="Silakan isi berapa tahun di sini"
                  v-model="field.durasi"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "createEmployee",
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      loading: false,
      showPickerLahir: "",
      showPickerMasuk: "",
      showPickerKeluar: "",
      errors: {
        email: null,
        password: null,
      },
      optGender: [
        { text: "Laki-laki", val: "Laki-laki" },
        { text: "Perempuan", val: "Perempuan" },
      ],
      optPendidikanTerakhir: [
        { text: "SD", val: "SD" },
        { text: "SMP", val: "SMP" },
        { text: "SMA", val: "SMA" },
        { text: "S1", val: "S1" },
        { text: "S2", val: "S2" },
        { text: "S3", val: "S3" },
      ],
      optGaji: [
        { text: "< Rp. 3.000.000", val: "< Rp. 3.000.000" },
        {
          text: "Rp. 3.000.000 - Rp. 6.000.000 ",
          val: "Rp. 3.000.000 - Rp. 6.000.000",
        },
        { text: "> Rp. 6.000.000", val: "> Rp. 6.000.000" },
      ],

      field: {
        username: "",
        nama: "",
        email: "",
        phone: "",
        alamat: "",
        password: "",
        nip: "",
        no_sipp: "",
        tgl_lahir: "",
        jenis_kelamin: "",
        department: "",
        jabatan: "",
        tgl_masuk: "",
        status_associate: "",
        tgl_keluar: "",
        tipe_associate: "",
        gaji: "",
        durasi: "",
        pendidikan_terakhir: "",
      },
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Akun Associate",
          disabled: false,
          href: `/admin/mk/associate`,
        },
        {
          text: "Tambah Akun Associate",
          disabled: true,
          href: `#`,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    // tambah akun asosiate dan kirim ke be
    createData() {
      let isEmpty = false;
      this.errors = {
        email: null,
        password: null,
      };
      for (const item in this.field) {
        if (!this.field[item]) {
          if (
            item == "username" ||
            item == "nama" ||
            item == "email" ||
            item == "password" ||
            item == "nip" ||
            item == "jenis_kelamin"
          ) {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }
      if (!isEmpty) {
        this.loading = true;
        let data = {
          body: {
            klien_id: this.profile.data.profile.klien_id,
            username: this.field.username,
            nama: this.field.nama,
            email: this.field.email,
            telepon: this.field.phone,
            alamat: this.field.alamat,
            password: this.field.password,
            nip: this.field.nip,
            no_sipp: this.field.no_sipp,
            tgl_lahir: this.field.tgl_lahir,
            jenis_kelamin: this.field.jenis_kelamin,
            departemen: this.field.department,
            jabatan: this.field.jabatan,
            tgl_masuk: this.field.tgl_masuk,
            status_associate: this.field.status_associate,
            tgl_keluar: this.field.tgl_keluar,
            tipe_associate: this.field.tipe_associate,
            durasi: this.field.durasi,
            pendidikan_terakhir: this.field.pendidikan_terakhir,
            gaji: this.field.gaji,
          },
          path: `sysadmin/associate/ASSOCIATE`,
        };
        this.$store
          .dispatch("client/postApi", data)
          .then((data) => {
            this.loading = false;
            if (data.code == 200) {
              for (let i in this.field) {
                this.field[i] = "";
              }
              this.$emit("success");
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
            } else if (data.code == 201) {
              if (data.data) {
                var key = Object.keys(data.data.errors);
                var values = Object.values(data.data.errors);
                for (let i = 0; i < key.length; i++) {
                  for (let j = 0; j < values[i].length; j++) {
                    // tampilkan semua error di pop up
                    this.$toast.error(values[i][j]);
                  }
                }
              } else {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
            }
          })
          .catch((e) => {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                // tampilkan semua error di pop up
                this.$toast.error(values[i][j]);
              }
            }
            this.loading = false;
          });
      }
    },
    closeDateMenu(item, type) {
      if (type == "lahir") {
        this.showPickerLahir = false;
        this.field.tgl_lahir == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else if (type == "masuk") {
        this.showPickerMasuk = false;
        this.field.tgl_masuk == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.showPickerKeluar = false;
        this.field.tgl_keluar == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
  },
};
</script>
